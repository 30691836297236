import React, { useEffect, useState, useRef } from 'react';
import './EducationTaskPage.scss';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import { Switch, Route } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { AxiosResponse } from 'axios';
import { Logo } from '../../shared/logo';
import { AllQuestionsImage } from './allquestionsIcon';
import { Popup } from '../../components/Popup/Popup';
import { TestService } from '../../services/dataServices/TestService';
import { QuestionService } from '../../services/dataServices/QuestionService';
import { useStores } from '../../custom-hooks/store/use-stores';
import { Spinner } from '../../components/Spinner/Spinner';
import { ReportService } from '../../services/dataServices/ReportService';
import { Timer } from '../../components/Timer/Timer';
import { Test, Answer, Question, Report } from '../../types';
import { Markdown } from '../../components/MarkDown/MarkDown';
import { ResultPage } from '../ResultPage/ResultPage';
import { setIntervalWorker } from './worker_script';
import { getTimerValue } from './getTimerValue';
import { getTaskTime } from './getTaskTime';
import { useMaxActiveQuestion } from '../../hooks/useMaxActiveQuestion';
import { QuestionTypeID, TestQuestions } from '../TestQuestionPage/TestQuestions';
import { Button } from '../../components/Button/Button';

const NO_ANSWER_TEXT = 'No answer is provided yet';

const createWorker = () => {
  return new Worker(setIntervalWorker);
};

function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const initialActive = -1;

const getQuestionType = (questions: Question[], answer: Answer): number | undefined => questions.find(q => q._id === answer.questionId)?.questionType;

export const EducationTaskPage = () => {
  const {
    userStore: {
      user: { _id: userId, assessment: assessmentId },
    },
  } = useStores();

  const history = useHistory();
  const { path } = useRouteMatch();
  const { testId } = useParams<{ testId: string }>();
  const match = useRouteMatch();

  const [openPopup, setOpenPopup] = useState(false);
  const [test, setTest] = useState<Test>();
  const [loading, setLoading] = useState(true);

  const [activeQuestion, setActiveQuestion] = useState(initialActive);
  const [currentQuestionInfo, setCurrentQuestionInfo] = useState<Question>();
  const { maxActiveQuestion, getMaxActiveQuestion } = useMaxActiveQuestion(activeQuestion, userId, assessmentId);

  const [userChosenAnswer, setUserChosenAnswer] = useState<Answer[]>([]);
  const [userTypedAnswer, setUserTypedAnswer] = useState('');
  const [chosenAnswer, setChosenAnswer] = useState<Answer>();

  const [timerId, setTimerId] = useState<number | undefined>();
  const [timer, setTimer] = useState<number>(60000);
  const [initialTimer, setInitialTimer] = useState<number>(60000);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: '',
  });
  const timerWorker = useRef(createWorker());
  const testStartedTimeQuery = `testStartedTime:${testId}${userId}${assessmentId}`;

  const isTaskCompleted = (question: number, testInfo: any) => question + 2 > (testInfo?.report?.answers?.length || 0);

  useEffect(() => {
    const typedAnswer = userChosenAnswer[activeQuestion]?.code?.message || '';
    setUserTypedAnswer(typedAnswer);
  }, [activeQuestion]);

  useEffect(() => {
    timerWorker.current.postMessage({ turn: 'on' });
    for (const key in localStorage) { // eslint-disable-line
      if (key.startsWith('timer')) {
        localStorage.removeItem(key);
      }
    }
  }, []);

  useEffect(() => {
    const preventBack = () => {
      window.history.forward();
      window.onunload = null;
    };

    window.addEventListener('popstate', preventBack);
    window.addEventListener('pushstate', preventBack);
    return () => {
      window.removeEventListener('popstate', preventBack);
      window.removeEventListener('pushstate', preventBack);
    };
  }, []);

  useEffect(() => {
    setLoading(true);
    TestService.getTest(testId, {
      params: { populate: true, candidate: userId },
    })
      .then((resp) => {
        setTest(resp.data);
        setTimer(resp.data.duration * 1000 * 60);
        setInitialTimer(resp.data.duration * 1000 * 60);
        setActiveQuestion(getMaxActiveQuestion(resp.data._id));
      })
      .finally(() => {
        setLoading(false);
      });

    return () => {
      setLoading(false);
    };
  }, []);

  useEffect(() => {
    if (!test) {
      return;
    }

    const questionID = test?.report.answers[activeQuestion].questionId;

    async function fetchData() {
      const question = await QuestionService.findOrGetQuestion(test?.questions || [], questionID);
      setCurrentQuestionInfo(question);
    }

    fetchData();
  }, [activeQuestion]);

  useEffect(() => {
    if (!test || maxActiveQuestion < 0) {
      return;
    }
    localStorage.setItem(`maxQuestion:${test?._id}${userId}${assessmentId}`, maxActiveQuestion.toString());
  }, [maxActiveQuestion, test?._id]);

  useEffect(() => {
    if (timerId) {
      localStorage.setItem('test', `${testId}${userId}${assessmentId}`);
    }
  }, [timerId, timer]);

  useEffect(() => {
    if (timer - 1000 < 0) {
      clearInterval(timerId!);

      const preparedUserAnswers = [...userChosenAnswer];

      const questionType = getQuestionType(test?.questions || [], preparedUserAnswers[activeQuestion]);
      if (questionType === QuestionTypeID.Code_Mirror) {
        const questionMessage = preparedUserAnswers[activeQuestion]!.code!.message;
        preparedUserAnswers[activeQuestion]!.code!.message = questionMessage === '' || questionMessage === ' ' ? NO_ANSWER_TEXT : questionMessage;
      }

      const data = {
        assessment: assessmentId,
        candidate: userId,
        test: `${testId}`,
        answers: [...preparedUserAnswers],
        isCompleted: true,
        time: getTaskTime(localStorage.getItem(testStartedTimeQuery), new Date().getTime().toString()),
      };

      setLoading(true);
      ReportService.updateReport((test?.report._id || ''), data).finally(() => {
        setLoading(false);
      });

      history.replace(`${match.url}/results`);
      localStorage.removeItem(testStartedTimeQuery);
      localStorage.removeItem(`maxQuestion:${test?._id}${userId}${assessmentId}`);
      localStorage.removeItem('test');
      timerWorker.current.postMessage({ turn: 'off' });
    }
  }, [timer, timerId]);

  useEffect(() => {
    let allChosenAnswer: Answer;
    if (currentQuestionInfo?._id) {
      const alreadyAnswered = test?.report.answers.find(a => a.questionId === currentQuestionInfo?._id);
      if (alreadyAnswered) {
        setChosenAnswer(alreadyAnswered);
        return;
      }

      allChosenAnswer = {
        questionId: currentQuestionInfo._id,
        answerIds: [],
      };

      if (currentQuestionInfo?.questionType === QuestionTypeID.Code_Mirror) {
        allChosenAnswer = {
          questionId: currentQuestionInfo._id,
          code: {
            message: '',
            languageId: currentQuestionInfo?.code?.languageId,
          },
        };
      }
      setChosenAnswer(allChosenAnswer);
    }
  }, [activeQuestion, currentQuestionInfo]);

  useEffect(() => {
    const allChosenAnswers = [...userChosenAnswer];
    const answerToChangeIndex = allChosenAnswers.findIndex((answer) => answer.questionId === chosenAnswer?.questionId);

    if (activeQuestion !== answerToChangeIndex) {
      return;
    }

    const fullInfoAboutAnswer = allChosenAnswers[answerToChangeIndex];

    // TODO
    allChosenAnswers[answerToChangeIndex] = {
      ...fullInfoAboutAnswer,
      ...chosenAnswer!,
      answered: !!(chosenAnswer?.answerIds && chosenAnswer?.answerIds.length) || (Boolean(chosenAnswer?.code?.message) && chosenAnswer?.code?.message !== NO_ANSWER_TEXT),
      visited: activeQuestion < getMaxActiveQuestion(test?._id || ''),
    };
    setUserChosenAnswer(allChosenAnswers);
  }, [chosenAnswer]);

  useEffect(() => {
    if (!test?.report.answers.length || activeQuestion < 0) {
      return;
    }
    const question = test?.report.answers[activeQuestion];
    if (!question) {
      const preparedUserAnswers = [...userChosenAnswer];
      const questionType = getQuestionType(test.questions, preparedUserAnswers[activeQuestion]);
      if (questionType === QuestionTypeID.Code_Mirror) {
        const questionMessage = preparedUserAnswers[activeQuestion]!.code!.message;
        preparedUserAnswers[activeQuestion]!.code!.message = questionMessage === '' || questionMessage === ' ' ? NO_ANSWER_TEXT : questionMessage;
      }

      const data = {
        assessment: assessmentId,
        candidate: userId,
        test: `${testId}`,
        answers: [...preparedUserAnswers],
        isCompleted: isTaskCompleted(activeQuestion, test),
        time: isTaskCompleted(activeQuestion, test)
          ? getTaskTime(localStorage.getItem(testStartedTimeQuery), new Date().getTime().toString())
          : '',
      };

      setLoading(true);
      ReportService.updateReport(test?.report._id, data).finally(() => {
        setLoading(false);
      });

      history.replace(`${match.url}/results`);
      localStorage.removeItem(`maxQuestion:${test?._id}${userId}${assessmentId}`);
      localStorage.removeItem('test');
      localStorage.removeItem(testStartedTimeQuery);
      clearInterval(timerId!);
      timerWorker.current.postMessage({ turn: 'off' });
      return;
    }

    setTimer(getTimerValue(testId, test, userId, assessmentId));
    setTimerId(undefined);

    const handleMessage = () => {
      setTimer((prevTimer) => prevTimer - 1000);
    };

    timerWorker.current.addEventListener('message', handleMessage);
    const timeoutId = new Date().getTime();
    setTimerId(timeoutId);

    return () => {
      timerWorker.current.removeEventListener('message', handleMessage);
      clearInterval(timeoutId);
    };
  }, [activeQuestion, history, test]);

  useEffect(() => {
    if (!test) {
      return;
    }

    const preparedAnswers = test.report.answers.map(
      (currAnswer, index) => {
        const message = currAnswer?.code?.message || '';
        return {
          _id: currAnswer._id,
          questionId: currAnswer.questionId,
          answered: !!(currAnswer?.answerIds && currAnswer?.answerIds.length) || (Boolean(currAnswer?.code?.message) && currAnswer?.code?.message !== NO_ANSWER_TEXT),
          visited: index < getMaxActiveQuestion(test._id),
          answerIds: currAnswer?.answerIds,
          code: {
            message,
            languageId: currAnswer.code?.languageId || null,
          },
        };
      },
    );
    setUserChosenAnswer(preparedAnswers);
  }, [maxActiveQuestion]);

  useEffect(() => {
    if (activeQuestion < (test?.report.answers.length || 0) && location.pathname !== `/education-tasks/${testId}/results`) {
      history.replace(`${match.url}/question/${activeQuestion + 1}`);
    }
  }, [activeQuestion, testId, test]);

  const handleNext = async (answerChanged?: boolean) => {
    const preparedUserAnswers = [...userChosenAnswer];
    const activeQuestionIndex = preparedUserAnswers.findIndex((answer) => answer.questionId === chosenAnswer?.questionId);
    preparedUserAnswers[activeQuestionIndex].visited = true;

    const questionType = getQuestionType(test?.questions || [], preparedUserAnswers[activeQuestionIndex]);
    if (questionType === QuestionTypeID.Code_Mirror) {
      const questionMessage = preparedUserAnswers[activeQuestionIndex]!.code!.message;
      preparedUserAnswers[activeQuestionIndex]!.code!.message = questionMessage === '' || questionMessage === ' ' ? NO_ANSWER_TEXT : questionMessage;
    }
    setUserChosenAnswer(preparedUserAnswers);

    const data = {
      assessment: assessmentId,
      candidate: userId,
      test: `${testId}`,
      answers: preparedUserAnswers,
      isCompleted: isTaskCompleted(activeQuestion, test),
      time: isTaskCompleted(activeQuestion, test)
        ? getTaskTime(localStorage.getItem(testStartedTimeQuery), new Date().getTime().toString())
        : '',
    };

    setLoading(true);
    try {
      const reportResponse = await ReportService.updateReport(test?.report?._id || '', data) as AxiosResponse<Report>;
      const updatedTest = { ...test!, report: reportResponse.data };
      setTest(updatedTest);
    } catch (err) {
      setSnackbar({
        open: true,
        message: 'Oops, unable to update the report',
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }

    if (data.isCompleted && !answerChanged) {
      history.replace(`${match.url}/results`);
      localStorage.removeItem(test?._id || '');
      localStorage.removeItem('test');
      localStorage.removeItem(testStartedTimeQuery);
      clearInterval(timerId!);
      timerWorker.current.postMessage({ turn: 'off' });
      return;
    }

    if (!answerChanged) {
      setActiveQuestion(activeQuestion + 1);
    }
  };

  const navigateToQuestion = (index: number) => {
    handleNext(true);
    setActiveQuestion(index);
    setOpenPopup(false);
  };

  const handlePrev = () => {
    navigateToQuestion(activeQuestion - 1);
  };

  const handleClose = (reason: any) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  if (loading) {
    return <Spinner />;
  }

  const openAnswersPopup = () => {
    setOpenPopup(true);
  };

  return (
    <>
      <Switch>
        <Route
          path={`${path}/results`}
          render={() => {
            return <ResultPage />;
          }}
        />
        <Route
          path={`${path}/question/:questionId`}
          render={() => {
            return (
              <div className="educationTask-page">
                <div className="educationTask-page__logo">
                  <Logo />
                </div>
                <div className="educationTask-page__container">
                  <h1>{test?.name}</h1>
                  <div
                    className="allQuestions"
                    onClick={() => {
                      openAnswersPopup();
                    }}
                  >
                    <div>
                      <AllQuestionsImage />
                    </div>
                    <div className="allQuestions__text">See all questions</div>
                  </div>
                  <Timer
                    data-testid="timer"
                    timer={timer}
                    redTimerTime={initialTimer <= 300000 ? 60000 : 300000}
                  />
                  <div className="task">
                    <div className="task-title">
                      Question {activeQuestion + 1} of{' '}
                      {test?.report.answers.length}
                    </div>
                    <div className="task-body">
                      <Markdown
                        className="textarea-with-md__markdown questionHeader"
                        value={currentQuestionInfo?.body || ''}
                      />
                    </div>
                  </div>

                  <div className="test-questions">
                    <TestQuestions
                      questionType={currentQuestionInfo?.questionType}
                      testQuestions={currentQuestionInfo}
                      activeQuestion={activeQuestion}
                      userTypedAnswer={userTypedAnswer}
                      setUserTypedAnswer={setUserTypedAnswer}
                      chosenAnswer={chosenAnswer}
                      setChosenAnswer={setChosenAnswer}
                      testType="education"
                      setSnackbar={setSnackbar}
                    />
                  </div>

                  <div className="btn-container">
                    {activeQuestion === 0 ? (
                      <div />
                    ) : (
                      <div className="btn-container__wrapper">
                        <Button
                          title="← Previous"
                          onClick={() => handlePrev()}
                          className="education-btn__prev"
                        />
                      </div>
                    )}
                    <div className="btn-container">
                      <Button
                        title={
                          activeQuestion + 1 === test?.report.answers.length
                            ? 'Finish'
                            : 'Next →'
                        }
                        onClick={() => handleNext()}
                        className="education-btn"
                      />
                    </div>
                  </div>
                </div>

                <Snackbar
                  open={snackbar.open}
                  autoHideDuration={3000}
                  onClose={handleClose}
                >
                  <Alert
                    elevation={6}
                    variant="filled"
                    onClose={handleClose}
                    severity={snackbar.severity}
                  >
                    {snackbar.message}
                  </Alert>
                </Snackbar>
                <Popup
                  title="All Questions"
                  isOpen={openPopup}
                  navigateToQuestion={(index) => navigateToQuestion(index)}
                  activeQuestion={activeQuestion}
                  onClose={() => setOpenPopup(false)}
                  questionsCollection={userChosenAnswer}
                  maxAnswered={getMaxActiveQuestion(test?._id || '')}
                />
              </div>
            );
          }}
        />
      </Switch>
    </>
  );
};
