import React from 'react';
import './Popup.scss';
import { ModalWrapper } from '../ModalWrapper/ModalWrapper';
import { CrossImage } from '../../shared/cross';
import { ProceedIcon } from '../../shared/proceed';
import { Answer } from '../../types';

type PopupProps = {
  title: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  questionsCollection: Answer[];
  navigateToQuestion: (index: number) => void,
  activeQuestion: number;
  maxAnswered: number;
};

const renderResults = (str: string, additionaClassName?: string) => (
  <div className={`questionAnswered-status ${additionaClassName && additionaClassName} `}>{str}</div>
);

export const Popup = ({
  title,
  isOpen,
  onClose,
  maxAnswered,
  questionsCollection,
  activeQuestion,
  navigateToQuestion,
}: PopupProps) => {
  return (
    <ModalWrapper isOpen={isOpen} onClose={onClose}>
      <div className="popup" data-testid="popup">
        <div onClick={onClose} className="popup-close">
          <CrossImage />
        </div>
        <div className="popup__title">{title}</div>
        <div>
          {questionsCollection.map((answer: any, index: number) => {
            return (
              <div className="questionAnswered" key={Math.random()}>
                <div className="questionAnswered-container">
                  <div className="questionAnswered-isAnswered">
                    <div
                      className={`${
                        maxAnswered >= index ? 'activeOrAnswered' : 'untouched'
                      } `}
                    >
                      Question {index + 1}
                    </div>

                    {!questionsCollection[index]?.visited
                    || (activeQuestion === index && !questionsCollection[index]?.visited) && (
                      renderResults('')
                    )}

                    {questionsCollection[index]?.answered
                      && questionsCollection[index]?.visited && (
                      renderResults('Answered')
                    )}

                    {!questionsCollection[index]?.answered
                      && questionsCollection[index]?.visited && (
                      renderResults('Not Answered', 'questionAnswered-status_err')
                    )}
                  </div>
                  <div>
                    {maxAnswered >= index && (
                      <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => navigateToQuestion(index)}
                      >
                        <ProceedIcon />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </ModalWrapper>
  );
};
