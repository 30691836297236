import React, { useState } from 'react';
import { Controlled as CodeMirror } from 'react-codemirror2';
import { Answer, Question } from '../../types';
import { PossibleAnswer } from '../../components/PossibleAnswerItem/PossibleAnswer';

import './TestQuestion.scss';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import { Info } from '../../shared/info';
import { Button } from '../../components/Button/Button';

import { ValidationService } from '../../services/dataServices/ValidationService';

require('codemirror/mode/javascript/javascript.js');

export enum QuestionTypeID {
  Single = 1,
  Code_Mirror = 2,
  Multiple = 3,
}

type TestQuestionType = {
  questionType: QuestionTypeID | undefined;
  testQuestions: Question | undefined;
  testType?: string;
  activeQuestion: number;
  userTypedAnswer: string;
  setUserTypedAnswer: any;
  chosenAnswer: Answer | undefined;
  setChosenAnswer: React.Dispatch<React.SetStateAction<Answer | undefined>>
  setSnackbar: React.Dispatch<React.SetStateAction<any | undefined>>
};

export const TestQuestions = ({
  questionType,
  testQuestions,
  userTypedAnswer,
  setChosenAnswer,
  chosenAnswer,
  setUserTypedAnswer,
  testType = '',
  setSnackbar = () => undefined,
}: TestQuestionType) => {
  const [codeValidationResult, setCodeValidationResult] = useState<any>({});

  const getValidationResults = async () => {
    const body = {
      code: userTypedAnswer,
      languageId: testQuestions?.code?.languageId,
    };
    try {
      const validationResults = await ValidationService.postValidation(body);
      setCodeValidationResult(JSON.stringify(validationResults?.data, null, 1));
    } catch (e: any) {
      setSnackbar({
        open: true,
        message: e.response.data.message,
        severity: 'error',
      });
    }
  };

  switch (questionType) {
    case QuestionTypeID.Single:
      return (
        <div>
          {testQuestions?.answers.map((singleAnswer) => {
            const singleAnswerId = (singleAnswer._id).toString();
            const possibleAnswer = { ...singleAnswer, _id: singleAnswerId };

            return (
              <PossibleAnswer
                data-testid="possibleAnswer"
                handleChosenAnswer={(answer) => {
                  setChosenAnswer((prevQuestion) => {
                    const nextQuestion: any = { ...prevQuestion };
                    nextQuestion.answerIds.splice(0, 1, answer._id);
                    return nextQuestion;
                  });
                }}
                isSelectedAnswer={chosenAnswer?.answerIds?.includes(singleAnswerId)}
                key={Math.random()}
                answer={possibleAnswer}
              />
            );
          })}
        </div>
      );
    case QuestionTypeID.Code_Mirror:
      if (testType === 'education') {
        return (
          <div className="answer">
            <div className="answer-title">
              <div>Your Answer</div>
              <div className="answer-title__coding">
                <Button
                  title="Validate"
                  onClick={() => getValidationResults()}
                  className="education-btn"
                />
                <div className="answer-title__coding-hint">
                  <div>
                    <Info />
                  </div>
                  <div className="answer-title__coding-hint__text">
                    Please note in order to run the function, please
                    call this function and pass parameters. Once you’re
                    done with validation, remove these lines
                  </div>
                </div>
              </div>
            </div>
            <div>
              <CodeMirror
                value={userTypedAnswer}
                data-testid="codeMirror"
                className="codeMirror"
                options={{
                  lineNumbers: true,
                }}
                onBeforeChange={(editor, data, value) => {
                  setUserTypedAnswer(value);
                  setChosenAnswer((prevQuestion) => {
                    const nextQuestion: any = {
                      ...prevQuestion,
                      code: {
                        ...prevQuestion?.code,
                        message: value,
                      },
                    };
                    return nextQuestion;
                  });
                }}
              />
            </div>
            {codeValidationResult.length ? (
              <div className="code-validation">
                <CodeMirror
                  value={codeValidationResult}
                  data-testid="codeMirror"
                  className="codeMirror"
                  options={{
                    lineNumbers: true,
                    readOnly: true,
                  }}
                  onBeforeChange={() => {}}
                />
              </div>
            ) : null}
          </div>
        );
      }
      return (
        <CodeMirror
          value={userTypedAnswer}
          data-testid="codeMirror"
          className="codeMirror"
          options={{
            lineNumbers: true,
          }}
          onBeforeChange={(editor, data, value) => {
            setUserTypedAnswer(value);
            setChosenAnswer((prevQuestion) => {
              const nextQuestion: any = {
                ...prevQuestion,
                code: {
                  ...prevQuestion?.code,
                  message: value,
                },
              };
              return nextQuestion;
            });
          }}
        />
      );
    case QuestionTypeID.Multiple:
      return (
        <div>{
          testQuestions?.answers.map((singleAnswer) => {
            const singleAnswerId = (singleAnswer._id).toString();
            const possibleAnswer = { ...singleAnswer, _id: singleAnswerId };
            return (
              <PossibleAnswer
                handleChosenAnswer={(answer, checked) => {
                  setChosenAnswer((prevQuestion) => {
                    const nextQuestion: any = { ...prevQuestion };
                    if (checked) {
                      if (!nextQuestion.answerIds.includes(answer._id)) {
                        nextQuestion.answerIds.push(answer._id);
                      }
                    } else {
                      nextQuestion.answerIds = nextQuestion.answerIds.filter(
                        (answerId: number) => (answerId).toString() !== answer._id,
                      );
                    }

                    return nextQuestion;
                  });
                }}
                isSelectedAnswer={chosenAnswer?.answerIds?.includes(singleAnswerId)}
                key={Math.random()}
                questionType={testQuestions?.questionType}
                answer={possibleAnswer}
              />
            );
          })
        }
        </div>
      );
    default:
      return null;
  }
};
