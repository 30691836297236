import React from 'react';
import './PossibleAnswer.scss';
import { Radio } from '../Radio/Radio';
import { Markdown } from '../MarkDown/MarkDown';
import { CheckBox } from '../CheckBox/CheckBox';

interface IPossibleAnswer {
  _id: string;
  body: string;
}

type PossibleAnswerProps = {
  answer: IPossibleAnswer;
  isSelectedAnswer?: boolean;
  questionType?: any;
  handleChosenAnswer: (value: IPossibleAnswer, checked: boolean) => void;
};

export const PossibleAnswer = ({
  answer,
  isSelectedAnswer,
  handleChosenAnswer,
  questionType,
}: PossibleAnswerProps) => {
  const onChange = (checked: boolean) => {
    handleChosenAnswer(answer, checked);
  };

  return (
    <div className="possibleAnswer" data-testid="possibleAnswer">
      {questionType ? (
        <div>
          <CheckBox onChange={onChange} checked={Boolean(isSelectedAnswer)}>
            <Markdown
              className="textarea-with-md__markdown questionAnswerFormatted"
              value={answer.body}
            />
          </CheckBox>
        </div>
      ) : (
        <div>
          <Radio onChange={onChange} checked={isSelectedAnswer}>
            <Markdown
              className="textarea-with-md__markdown questionAnswerFormatted"
              value={answer.body}
            />
          </Radio>
        </div>
      )}
    </div>
  );
};
